import React from 'react';
import FeedbackForm from '../components/FeedbackForm';

export default function Feedback() {
  return (
    <div>
      <FeedbackForm />
    </div>
  );
}
