import React, { useState } from 'react';
import {
  Card, CardContent, CardHeader, Typography, TextField, Box, Button, Snackbar, Alert,
} from '@mui/material';
import request from '../ApiRequest';
import isDev from '../DevDetect';

interface EncodeState {
  message: string,
  key: string,
  output: string
}

type EncodeErrorResponse = {
  msg: [{
    Error: {
      message: string[]
    },
  }],
  status: 'error'
};

type EncodeSuccessResponse = {
  message: string
};

// Source: https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions
type EncodeResponse = EncodeSuccessResponse | EncodeErrorResponse;

interface ToastState {
  open: boolean,
  message: string,
  severity: 'error' | 'success'
}

export default function Encode() {
  const [data, setData] = useState<EncodeState>({
    message: 'The private secret message',
    key: 'snowden@gmail.com',
    output: '',
  });

  const [toast, setToast] = React.useState<ToastState>({
    open: false,
    message: '',
    severity: 'error',
  });

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      message: e.target.value,
    });
  };

  const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      key: e.target.value,
    });
  };

  const handleEncodeButtonClick = () => {
    request<EncodeResponse>(isDev() ? 'http://0.0.0.0:3000/encode' : 'https://api.lexitrace.com/encode', {
      method: 'POST',
      body: JSON.stringify({
        message: data.message,
        key: data.key,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => { // NO error making request
      if (response.ok) {
        if ('message' in response.data) { // Normal response
          setData({
            ...data,
            output: response.data.message,
          });
        } else if ('status' in response.data && response.data.status === 'error') { // Backend error
          setData({
            ...data,
            output: '',
          });

          setToast({ message: response.data.msg[0].Error.message[0], severity: 'error', open: true });
        }
      }
    }).catch(() => { // Error making request
      setToast({ message: 'Error making request', severity: 'error', open: true });
    });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ ...toast, open: false });
  };

  return (
    <div>
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast.severity} sx={{ width: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Card sx={{ my: 2 }}>
        <CardHeader title="Encode" sx={{ pb: 0 }} />
        <CardContent>
          <Typography component="div" variant="body1">
            <Box sx={{ mb: 2 }}>
              <TextField
                id="outlined-multiline-static"
                label="Text to Encode"
                multiline
                rows={4}
                sx={{ width: '100%' }}
                required
                value={data.message}
                onChange={handleMessageChange}
                InputProps={{ style: { letterSpacing: 'normal' } }}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <TextField
                required
                id="outlined-required"
                label="Key"
                value={data.key}
                onChange={handleKeyChange}
                InputProps={{ style: { letterSpacing: 'normal' } }}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <Button
                variant="contained"
                onClick={handleEncodeButtonClick}
              >
                Encode
              </Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="outlined-multiline-static"
                label="Encoded Text"
                multiline
                rows={4}
                sx={{ width: '100%' }}
                value={data.output}
                InputProps={{ style: { letterSpacing: 'normal' } }}
              />
            </Box>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
