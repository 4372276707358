import Box from '@mui/material/Box';
import React from 'react';
import Encode from '../components/Encode';
import Decode from '../components/Decode';

function Demo() {
  return (
    <div>
      <Encode />
      <Decode />
      <Box />
    </div>
  );
}

export default Demo;
