import React, { useState } from 'react';
import {
  Box, Button,
  Card, CardContent, CardHeader, TextField, Typography, Snackbar, Alert,
} from '@mui/material';
import request from '../ApiRequest';
import isDev from '../DevDetect';

interface DecodeState {
  message: string,
  output: string
}

type DecodeErrorResponse = {
  msg: [{
    decode: string
  }],
  status: 'error'
};

type EncodeSuccessResponse = {
  key: string
};

type DecodeResponse = DecodeErrorResponse | EncodeSuccessResponse;

interface ToastState {
  open: boolean,
  message: string,
  severity: 'error' | 'success'
}

export default function Decode() {
  const [data, setData] = useState<DecodeState>({
    message: '',
    output: '',
  });

  const [toast, setToast] = React.useState<ToastState>({
    open: false,
    message: '',
    severity: 'error',
  });

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      message: e.target.value,
    });
  };

  const handleDecodeButtonClick = () => {
    request<DecodeResponse>(isDev() ? 'http://0.0.0.0:3000/decode' : 'https://api.lexitrace.com/decode', {
      method: 'POST',
      body: JSON.stringify({
        message: data.message,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => { // NO error making request
      if (response.ok) {
        if ('key' in response.data) { // Normal response
          setData({
            ...data,
            output: response.data.key,
          });
        } else if ('status' in response.data && response.data.status === 'error') { // Backend error
          setData({
            ...data,
            output: '',
          });

          setToast({ message: response.data.msg[0].decode, severity: 'error', open: true });
        }
      }
    }).catch(() => { // Error making request
      setToast({ message: 'Error making request', severity: 'error', open: true });
    });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ ...toast, open: false });
  };

  return (
    <div>
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast.severity} sx={{ width: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Card sx={{ my: 2 }}>
        <CardHeader title="Decode" sx={{ pb: 0 }} />
        <CardContent>
          <Typography component="div" variant="body1">
            <Box sx={{ mb: 2 }}>
              <TextField
                id="outlined-multiline-static"
                label="Text to Decode"
                multiline
                rows={4}
                sx={{ width: '100%' }}
                required
                onChange={handleMessageChange}
                value={data.message}
                InputProps={{ style: { letterSpacing: 'normal' } }}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <Button variant="contained" onClick={handleDecodeButtonClick}>Decode</Button>
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="outlined-required"
                label="Key"
                value={data.output}
                InputProps={{ style: { letterSpacing: 'normal' } }}
              />
            </Box>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
