import React, { useState } from 'react';
import {
  Card, CardContent, CardHeader, Typography, TextField, Box, Button, Snackbar, Alert,
} from '@mui/material';
import request from '../ApiRequest';
import isDev from '../DevDetect';

interface FeedbackState {
  name: string,
  email: string,
  message: string
}

type FeedbackErrorResponse = {
  msg: [{
    Error: {
      message: string[],
      email: string[]
    },
  }],
  status: 'error'
};

type FeedbackSuccessResponse = {
  message: string
};

// Source: https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions
type FeedbackResponse = FeedbackSuccessResponse | FeedbackErrorResponse;

interface ToastState {
  open: boolean,
  message: string,
  severity: 'error' | 'success'
}

export default function FeedbackForm() {
  const [data, setData] = useState<FeedbackState>({
    name: '',
    email: '',
    message: '',
  });

  const [toast, setToast] = React.useState<ToastState>({
    open: false,
    message: '',
    severity: 'error',
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      name: e.target.value,
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      email: e.target.value,
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      message: e.target.value,
    });
  };

  const handleFeedbackButtonClick = () => {
    request<FeedbackResponse>(isDev() ? 'http://0.0.0.0:3000/feedback' : 'https://api.lexitrace.com/feedback', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => { // NO error making request
      if (response.ok) {
        if ('message' in response.data) { // Normal response
          setData({
            message: '', email: '', name: '',
          });

          setToast({ message: 'Message sent', severity: 'success', open: true });
        } else if ('status' in response.data && response.data.status === 'error') { // Backend error
          const messageErrors: string[] = (response.data.msg[0].Error.message || []).map((i) => `Message ${i}`);
          const emailErrors: string[] = (response.data.msg[0].Error.email || []).map((i) => `Email ${i}`);

          const errorMessage = messageErrors.concat(emailErrors).join('\n');

          setToast({ message: errorMessage, severity: 'error', open: true });
        }
      }
    }).catch(() => { // Error making request
      setToast({ message: 'Error making request', severity: 'error', open: true });
    });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setToast({ ...toast, open: false });
  };

  return (
    <div>
      <Snackbar open={toast.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toast.severity} sx={{ width: '100%', whiteSpace: 'pre-wrap' }}>
          {toast.message}
        </Alert>
      </Snackbar>
      <Card sx={{ my: 2 }}>
        <CardHeader title="Feedback" sx={{ pb: 0 }} />
        <CardContent>
          <Typography component="div" variant="body1">
            <Box sx={{ mb: 2 }}>
              <Box sx={{ my: 2 }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Name"
                  value={data.name}
                  onChange={handleNameChange}
                  InputProps={{ style: { letterSpacing: 'normal' } }}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  value={data.email}
                  onChange={handleEmailChange}
                  InputProps={{ style: { letterSpacing: 'normal' } }}
                />
              </Box>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                sx={{ width: '100%' }}
                required
                value={data.message}
                onChange={handleMessageChange}
                InputProps={{ style: { letterSpacing: 'normal' } }}
              />
            </Box>
            <Box sx={{ my: 2 }}>
              <Button
                variant="contained"
                onClick={handleFeedbackButtonClick}
              >
                Send feedback
              </Button>
            </Box>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
