import React from 'react';
import { Link } from '@mui/material';
import book from '../images/book.png';
import diagram from '../images/diagram.png';
import lock from '../images/lock.png';
import lock_open from '../images/lock_open.png';
import mail from '../images/mail.png';
import news from '../images/news.png';
import people from '../images/people.png';
import secret from '../images/secret.png';

interface IProps {
  mode: 'light' | 'dark' | 'system'
}

export default function Info({ mode }: IProps) {
  return (
    <div className="snap larger-text">
      <section className="main-section flex-column-center">
        <h1 className="main-section-inside">
          Lexitrace adds secret hidden characters to text so that you can identify the origin of the message when it is distributed by others.
        </h1>
        <div className="large-icon">
          ⬇
        </div>
      </section>
      <section>
        <div>
          <h2>Value</h2>
          <p>
            “In 2022, the average cost of a data breach has reached a record high of US$4.35 million, according to the 2022 cost of a data breach report by IBM and the Ponemon institute.” (
            <Link href="https://www.upguard.com/blog/cost-of-data-breach" underline="none">Source</Link>
            )
          </p>
          <p><i>For a small cost, we will integrate our solution with your system, so that you can identify and deter future breaches in your system.</i></p>
        </div>
      </section>
      <section>
        <div>
          <h2>Use Cases</h2>
          <table>
            <tr>
              <td>
                <img src={news} alt="News" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
              <td>
                <ol>
                  <li>
                    <i>News</i>
                    {' '}
                    – Imprint a unique key in each of your articles, so that you can identify user accounts that are released on other websites.
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <img src={mail} alt="Mail" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
              <td>
                <ol start={2}>
                  <li>
                    <i>Email</i>
                    {' '}
                    – Email each recipient a different version of message and trace the person who leaked the message to other sources.
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <img src={book} alt="Book" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
              <td>
                <ol start={3}>
                  <li>
                    <i>Books</i>
                    {' '}
                    – Add symbols to ebooks that you sell and identify the user who pirated and distributed the ebooks.
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>
                <img src={secret} alt="Secret" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
              <td>
                <ol start={4}>
                  <li>
                    <i>Classified Documents</i>
                    {' '}
                    – Add hidden symbols to documents that are unique for each user and trace who released the document
                  </li>
                </ol>
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section>
        <div>
          <h2>Method</h2>
          <table>
            <tr>
              <td>
                <ol>
                  <li><i>Encode</i></li>
                  <ol type="a">
                    <li>Enter a message.</li>
                    <li>Define the recipients.</li>
                    <li>The system will generate different versions of the message, each slightly different.</li>
                  </ol>
                </ol>
              </td>
              <td>
                <img src={lock} alt="Lock" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
            </tr>
            <tr>
              <td>
                <ol start={2}>
                  <li><i>Distribute</i></li>
                  <ol type="a"><li>Release the different variations of the message to a set of each of the recipients.</li></ol>
                </ol>
              </td>
              <td>
                <img src={people} alt="People" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
            </tr>
            <tr>
              <td>
                <ol start={3}>
                  <li><i>Decode and Track</i></li>
                  <ol type="a">
                    <li>Copy and paste the leaked text back into the tool.</li>
                    <li>The system will decode the hidden key and identify the origin of the leaked content.</li>
                  </ol>
                </ol>
              </td>
              <td>
                <img src={lock_open} alt="Lock Open" className={`icon-image ${mode === 'light' ? '' : 'icon-image-dark'}`} />
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="flex-row-center">
                <img src={diagram} alt="Diagram" className="diagram-image" />
              </td>
            </tr>
          </table>
        </div>
      </section>
      <section>
        <div>
          <h2>Sources</h2>
          <ol>
            <li>
              Canary trap (
              <Link href="https://en.wikipedia.org/wiki/Canary_trap" underline="none">Source</Link>
              )
            </li>
            <li>
              “Elon Musk shared that they identified the accused by sending emails that looked identical to all its employees but each email was coded with different spaces.” (
              <Link href="https://www.ndtv.com/world-news/elon-musk-explains-how-tesla-caught-employee-leaking-data-3433802" underline="none">Source</Link>
              )
            </li>
            <li>
              Digital fingerprinting used for tracking movie piracy. (
              <Link href="https://ijirt.org/master/publishedpaper/IJIRT154254_PAPER.pdf" underline="none">Source</Link>
              )
            </li>
            <li>
              Hidden unicode characters (
              <Link href="https://www.soscisurvey.de/tools/view-chars.php" underline="none">Source</Link>
              )
            </li>
          </ol>
        </div>
      </section>
      <section>
        <div>
          <h2>Contact</h2>
          <div>
            If you are interested in integrating our application with yours by API, please contact us
            {' '}
            <Link href="/feedback" underline="none">here</Link>
            , and we can provide pricing.
          </div>
        </div>
      </section>
    </div>
  );
}
